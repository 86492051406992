<template>
  <div class="house-list-container">
    <Header :search='true' :info='searchInfo' @update-active='doUpdateActive' @search='doSearch' />
    <FilterInfo ref="filterInfo" :type="houseType" @change="doChangeFilter" />
    <el-tabs v-model="activeName" class="house-wrapper" type="card" @tab-click="handleClick">
      <el-tab-pane label="全部房源" name="first"></el-tab-pane>
      <el-tab-pane label="诚心卖房源" name="second"></el-tab-pane>
      <el-tab-pane label="房屋总价" name="third"></el-tab-pane>
      <el-tab-pane label="房屋面积" name="fourth"></el-tab-pane>
    </el-tabs>
    <HouseDetail
        @update='doFindHouseList'
        :type="houseType"
        :total='total'
        :list='dataList'
        :recommedList='recommedList'
        @sizeChange="doSizeChange"
        @pageChange="doPageChange"
    />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import FilterInfo from "@/components/FilterInfo.vue";
import HouseDetail from "@/components/HouseDetail.vue";
export default {
  name: "HouseList",
  components: { Header, FilterInfo, HouseDetail },
  data() {
    return {
      houseType: 1, // 1 售房  2 租房
      activeName: "first",
      searchInfo: null,
      params: {
        pageSize: 1,
        pageNum: 10,
        areaInfo: '',
        houseType: 1,
        sortType: 0,
        isRecommend: null
      },
      dataList: [],
      recommedList: [],
      total: 0
    };
  },
  mounted () {
    this.dataList = []
    this.searchInfo = this.$route.query
    if (this.searchInfo) {
      this.houseType = Number(this.searchInfo.active)
      this.params.houseType = Number(this.houseType)
    }
    this.doFindHouseList()
    this.doFindRecommendList()
  },
  methods: {
    goBackTop () {
      const timer = setInterval(function(){
        let osTop = document.documentElement.scrollTop || document.body.scrollTop;
        let ispeed = Math.floor(-osTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
        this.isTop = true;
        if(osTop === 0){
          clearInterval(timer);
        }
      },30)
    },
    doPageChange (page) {
      this.params.pageSize = page
      this.doFindHouseList()
      this.goBackTop()
    },
    doSizeChange (size) {
      this.params.pageNum = size
      this.doFindHouseList()
      this.goBackTop()
    },
    doSearch (keywords) {
      this.params.areaInfo = keywords
      this.doFindHouseList()
    },
    doChangeFilter (params) {
      params.houseType = this.houseType
      this.params = Object.assign({}, this.params, params)
      this.doFindHouseList()
    },
    doUpdateActive (type) {
      this.dataList = []
      this.$refs['filterInfo'].params.city = []
      this.params.city = []
      this.houseType = type
      this.params.houseType = type
      this.doFindHouseList()
    },
    doFindRecommendList () {
      this.$request.get('/api/house/queryRecommendMiddlemanList').then(res => {
        if (res.code === 200) {
          this.recommedList = res.data
        } else {
          this.recommedList = []
        }
      })
    },
    doFindHouseList () {
      this.$request.post(`/api/house/queryHouseList?pageSize=${this.params.pageNum}&pageNum=${this.params.pageSize}`, this.params)
          .then(res => {
        if (res.code === 200) {
          this.dataList = res.data.rows
          this.total = res.data.total
        } else {
          this.dataList = []
        }
      })
    },
    handleClick(tab) {
      switch(tab.name) {
        case 'first':
          this.params.isRecommend = null
          this.params.sortType = 0
          this.doFindHouseList()
          break
        case 'second':
          this.params.isRecommend = 1
          this.doFindHouseList()
          break
        case 'third':
          this.params.isRecommend = null
          this.params.sortType = this.params.sortType === 1 ? 2 : 1
          this.doFindHouseList()
          break
        case 'fourth':
          this.params.isRecommend = null
          this.params.sortType  = this.params.sortType === 3 ? 4 : 3
          this.doFindHouseList()
          break
      }
    }
  }
};
</script>
<style lang="scss">
@import "../styles/variable.scss";
.house-list-container {
  width: 1200px;
  margin: 0 auto;
  .house-wrapper {
    margin-top: 20px;
  }
  .el-tabs--card>.el-tabs__header {
    border-bottom: 2px solid $mainColor;
  }
  .el-tabs__item:hover {
    color: $mainColor;
  }
  .el-tabs__item.is-active {
    color: #fff;
    background-color: $mainColor;
    border-color: $mainColor;
  }
  .el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
    border-color: $mainColor;
  }
  .el-tabs--card>.el-tabs__header .el-tabs__nav {
    border-color: $mainColor;
    border-radius: 0;
  }
  .el-tabs--card>.el-tabs__header .el-tabs__item {
    border-color: $mainColor;
  }
}
</style>
